.input-area,
.input-area-mobile {
  width: 70%;
  float: right;
  font-size: 26px;
  height: 15vh;
  resize: none;
}

.input-area-mobile {
  width: 100%;
  font-size: 18px;
  float: none;
  margin-top: 20px;
}
