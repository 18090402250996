.app,
.app-locked {
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: #010101;
}

.app-locked {
  touch-action: none;
}

html,
body {
  overflow-x: hidden;
  touch-action: pan-y;
  height: auto;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Make scrollbar invisible */
}

/* The animation code */
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#home,
#about,
#career,
#projects,
#awards,
#volunteering,
#contact {
  width: 100%;
  overflow: hidden;
  animation-name: fade-in;
  animation-duration: 3s;
}

#footer {
  width: 100%;
  animation-name: fade-in;
  animation-duration: 3s;
}

@media (min-device-width: 320px) and (max-device-width: 480px) {
  .CookieConsent {
    font-size: 10px;
  }

  .cookie-buttons {
    margin: 0 auto;
    font-size: 8px;
  }

  #rcc-confirm-button,
  #rcc-decline-button {
    padding: 2px;
  }
}
